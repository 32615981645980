import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import ProgressBar from "@ramonak/react-progress-bar";
import { Image, TouchableOpacity,Linking} from 'react-native';
import {Link} from 'react-scroll'
import Marquee from "react-fast-marquee";
// import { Image,List } from 'semantic-ui-react';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


export const DiamondShape = styled.div`
{
  "diamond": {
    "borderStyle": "solid",
    "borderColor": "transparent transparent #0809fe transparent",
    "borderWidth": "0 25px 25px 25px",
    "height": "0",
    "width": "50px",
    "position": "relative",
    "margin": "10px 0"
  },
  "diamond_after": {
    "content": "\"\"",
    "position": "absolute",
    "top": "25px",
    "left": "-25px",
    "width": "0",
    "height": "0",
    "borderStyle": "solid",
    "borderColor": "#0809fe transparent transparent transparent",
    "borderWidth": "70px 50px 0 50px"
  }
}
`;
  
export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #36A3DA;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(54, 163, 218 , 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(54, 163, 218 , 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(54, 163, 218 , 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: #3F7FBF;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveWrapperTile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  margin-bottom: 20px;
  @media (min-width: 767px) {
    flex-direction: row;
    margin: 30px;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledSeparator = styled.img`
  width: 30%;
  margin: 15px;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledBanner = styled.img`
  transition: width 0.5s;
  transition: height 0.5s;
  height: 100px;
  @media (min-width: 767px) {
    height: 160px;
  }
  width: 100%;
  @media (min-width: 767px) {
    width: 100%;
  }
`;

export const StyledImg = styled.img`
  background-color: var(--accent);
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const DpImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  width: 120px;
  @media (min-width: 900px) {
    width: 140px;
  }
  @media (min-width: 1000px) {
    width: 180px;
  }
  transition: width 0.5s;
`;

export const DpTeam = styled.img`
  width: 120px;
  @media (min-width: 900px) {
    width: 140px;
  }
  @media (min-width: 1000px) {
    width: 180px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  // const [feedback, setFeedback] = useState(`Click BUY to mint your NFT.`);
  const [feedbackPublic, setFeedbackPublic] = useState(`Click BUY to mint your NFT.`);
  const [feedbackWhitelist, setFeedbackWhitelist] = useState(`Click BUY to mint your NFT.`);
  const [feedbackPartner, setFeedbackPartner] = useState(`Click BUY to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintProgressLabel, updateMintLabel] = useState(`Minting Chad`);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  const launchDate = new Date(CONFIG.LAUNCH_DATE) ;
  const openseaDate = new Date(CONFIG.OPENSEA_DATE) ;


  //claim whitelist free
  const claimNFTFreeWhite = () => {
    let cost = 0;
    setMintAmount(1);
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * 1);
    let totalGasLimit = String(gasLimit * 1);
    setFeedbackWhitelist(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mintFreeRoles(1)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedbackWhitelist("Sorry, something went wrong.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedbackWhitelist(
          `WOW, You minted ${mintAmount} Chads! visit OpenSea to view it`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  //claim public nft
  const claimPublicNFTs = () => {
    let cost = data.totalSupply <= CONFIG.MAX_FREE_MINT ? 0 :CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedbackPublic(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedbackPublic("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedbackPublic(
                    `WOW, You minted ${mintAmount} Chads! visit OpenSea to view it`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  //claim partner nft
  const claimPartnerNFTs = () => {
    let cost = CONFIG.WEI_PRTN_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    setFeedbackPartner(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mintForRoles(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedbackPartner("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedbackPartner(
            `WOW, You minted ${mintAmount} Chads! visit OpenSea to view it`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > CONFIG.MAX_MINT_PER_TX) {
      newMintAmount = CONFIG.MAX_MINT_PER_TX;
    }
    setMintAmount(newMintAmount);
  };

  const updateMintLabels = () => {
    if (data.totalSupply >= 0 & data.totalSupply <= 4000) {
      updateMintLabel("Chads Are Assembling");
    } else if (data.totalSupply > 4000 & data.totalSupply <=6000) {
      updateMintLabel("Apocalypse Has Begun");
    } else if (data.totalSupply > 6000 & data.totalSupply <=8000) {
      updateMintLabel("Zombies Taking Over The World");
    } else if (data.totalSupply > 8000 & data.totalSupply <=9000) {
      updateMintLabel("SpaceY Shuttle Fueled Up");
    } else if (data.totalSupply > 9000 & data.totalSupply <=9500) {
      updateMintLabel("SpaceY Shuttle Ready To Take Off");
    } else if (data.totalSupply > 9500 & data.totalSupply <=9575) {
      updateMintLabel("Chads Have Landed on Mars");
    } else if (data.totalSupply === 9999) {
      updateMintLabel("Mar Colony Inhabitable");
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    updateMintLabels();
  });

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/web-bg.jpg" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo3.png"} />
        <s.SpacerSmall />

        <s.BannerWeb>
        <Marquee speed="50">
        <StyledBanner  alt={"logo"} src={"/config/images/web-banner-hd.jpg"} />
        </Marquee>
        </s.BannerWeb>

        <s.BannerMobile>
        <StyledBanner alt={"logo"} src={"/config/images/1500x500_4_new.png"} 
                    style={{
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                    }}/>
        </s.BannerMobile>


<StyledSeparator alt={"logo"} src={"/config/images/separator-4.png"} />

<ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.SpacerLarge />
          
          <ResponsiveWrapperTile>
            <s.Container flex={1} jc={"left"} ai={"left"}>
            <s.TextTitle
              style={{
                textAlign: "left",
                fontSize: 30,
                color: "var(--tile-text)",
              }}
            >
              WELCOME TO THE
            </s.TextTitle>

            <s.TextTitle
              style={{
                textAlign: "left",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--white-text)",
              }}
            >
              {CONFIG.NFT_NAME}
            </s.TextTitle>

            <Link  to="minting" spy={true} smooth={true}>
            <StyledButton
            style={{
              width:120,
            }}>
                  MINT NOW
            </StyledButton>
            </Link>
            <s.SpacerSmall />
            <s.SpacerSmall />
            <s.SpacerSmall />


            <s.Container jc={"left"} ai={"left"}>
            <s.TextTitle
              style={{
                textAlign: "left",
                fontSize: 18,
                color: "var(--footer-text)",
              }}
            >
            MINTING NOW
            </s.TextTitle>

            <s.TextTitle
              style={{
                textAlign: "left",
                fontSize: 18,
                color: "var(--footer-text)",
              }}
            >
            REVEALING SOON
            </s.TextTitle>
            </s.Container>

            <s.ContainerLeft flex={1} jc={"left"} ai={"left"} flexDirection={"row"}>

                <TouchableOpacity onPress={() =>Linking.openURL(CONFIG.DISCORD_LINK)}>
                  <Image
                    source={{ uri: '/config/images/discord_icon.png' }}
                    // src = '/config/images/discord_icon.png'
                    style={{ width: 50, height: 50 , margin: 20}}
                    />
                </TouchableOpacity>
                <TouchableOpacity onPress={() =>Linking.openURL(CONFIG.TWITTER_LINK)}>
                  <Image
                    source={{ uri: '/config/images/twitter_icon.png' }}
                    // src = '/config/images/twitter_icon.png'
                    style={{ width: 50, height: 50 , margin: 20 }}
                    />
                </TouchableOpacity>
                {(new Date() > openseaDate ) ? (
                <TouchableOpacity onPress={() =>Linking.openURL(CONFIG.MARKETPLACE_LINK)}>
                  <Image
                    source={{ uri: '/config/images/logo-opensea.png' }}
                    style={{ width: 50, height: 50 , margin: 20}}
                    />
                </TouchableOpacity>
                ):("")}
            </s.ContainerLeft>
            
            
            </s.Container>

            <s.Container flex={1} jc={"center"} ai={"center"}>
              <StyledImg alt={"example"} src={"/config/images/hodler-chads-gif-1000.gif"} style={{borderRadius: 10}} />
            </s.Container>

            </ResponsiveWrapperTile>
        </ResponsiveWrapper>




        <StyledSeparator alt={"logo"} src={"/config/images/separator-4.png"} />
                  <s.TextTitle id="minting"  style={{ textAlign: "center", fontSize: 30,fontWeight: "bold",color: "var(--primary)",}}>
                  Chad's Minting
                  </s.TextTitle>
        <StyledSeparator alt={"logo"} src={"/config/images/separator-3.png"} />

        {(new Date() > launchDate ) ? (
        <ResponsiveWrapper flex={1}>

          <ResponsiveWrapperTile flex={1}>
          <s.Container flex={1} jc={"center"} ai={"center"}>

          <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--white-text)",
              }}
            > Current Mint Status
            </s.TextDescription>

          <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--white-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            </s.Container>

          </ResponsiveWrapperTile>

          </ResponsiveWrapper>
        ) :("")}

        <ResponsiveWrapper flex={2} style={{ padding: 24 }}>

          <ResponsiveWrapperTile>

          <s.Container flex={1} jc={"left"} ai={"center"}
              style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              maxWidth: 400,
              borderRadius: 24,
              border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >

            <StyledImg alt={"example"} src={"/config/images/chads-public-low.gif"} style={{borderRadius: 10, width:300}} />
            <s.Container flex={1} jc={"center"} ai={"center"} fd={"row"}>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 21,
                fontWeight: "bold",
                paddingRight:25,
                color: "var(--accent-text)",
              }}
            >
              PUBLIC MINT
            </s.TextTitle>

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 21,
                paddingLeft:15,
                fontWeight: "bold",
                marginBottom: 'auto',
                color: "var(--accent-text)",
              }}
            >
              {CONFIG.DISPLAY_COST} ETH
            </s.TextTitle>
            </s.Container>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
              <s.SpacerSmall />
                <s.TextTitle style={{
                textAlign: "center",
                fontSize: 23,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}>
                  Sold Out!
                </s.TextTitle>
                
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>
                  Check {CONFIG.MARKETPLACE} for Chads available for sale!
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                <StyledButton
                style={{
                width:120,
                }}>
                  {CONFIG.MARKETPLACE}
                </StyledButton>
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>

                    <s.SpacerSmall />

                    {(new Date() > launchDate ) ? (
                    <StyledButton
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      width:200,
                      fontWeight: "bold",
                    }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton> ) :(


                    <StyledButton style={{ textAlign: "center", fontSize: 20, width:200, fontWeight: "bold", }}
                      onClick={(e) => {
                        e.preventDefault();
                      }} >
                      MINTING SOON
                    </StyledButton>
                    )}

                  <s.SpacerSmall />
                  <s.TextDescription  jc={"left"} ai={"left"} style={{ textAlign: "", color: "var(--accent-text)" }}>
                  First {CONFIG.MAX_FREE_MINT} Public Mints Free
                </s.TextDescription>
                <s.TextDescription jc={"left"} ai={"left"} style={{ textAlign: "left", color: "var(--secondary-text)" }}>
                  Mint Chads FCFS
                </s.TextDescription>
                <s.TextDescription  jc={"left"} ai={"left"} style={{ textAlign: "", color: "var(--accent-text)" }}>
                  &#9889;
                </s.TextDescription>
                  </s.Container>
                  
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedbackPublic}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimPublicNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          
          </ResponsiveWrapperTile>

          <ResponsiveWrapperTile>
          
          
          <s.Container flex={1} jc={"left"} ai={"center"}
              style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              maxWidth: 400,
              border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >

            <StyledImg alt={"example"} src={"/config/images/chads-og-low.gif"} style={{borderRadius: 10, width:300}} />
            <s.Container flex={1} jc={"center"} ai={"center"} fd={"row"}>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 21,
                fontWeight: "bold",
                paddingRight:25,
                color: "var(--accent-text)",
              }}
            >
              DISCOUNTED MINT
            </s.TextTitle>

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 21,
                paddingLeft:15,
                fontWeight: "bold",
                marginBottom: 'auto',
                color: "var(--accent-text)",
              }}
            >
              {CONFIG.PARTNER_COST} ETH
            </s.TextTitle>
            </s.Container>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
              <s.SpacerSmall />
                <s.TextTitle style={{
                textAlign: "center",
                fontSize: 23,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}>
                  Sold Out!
                </s.TextTitle>
                
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>
                  Check {CONFIG.MARKETPLACE} for Chads available for sale!
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                <StyledButton
                style={{
                width:120,
                }}>
                  {CONFIG.MARKETPLACE}
                </StyledButton>
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>

                    <s.SpacerSmall />

                    {(new Date() > launchDate ) ? (
                    <StyledButton
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      width:200,
                      fontWeight: "bold",
                    }}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton> ) :(


                    <StyledButton style={{ textAlign: "center", fontSize: 20, width:200, fontWeight: "bold", }}
                      onClick={(e) => {
                        e.preventDefault();
                      }} >
                      MINTING SOON
                    </StyledButton>
                    )}
                        <s.SpacerSmall />
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>
                    Discounted Price Mint if you hold Partner NFTs
                </s.TextDescription>
                <s.TextDescription style={{ textAlign: "center", color: "var(--secondary-text)" }}>
                Partners are HABIBIZ, BAYC, MAYC, MICE, KONGZ 
                </s.TextDescription>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>
                  Discounted Mints for OG Chads
                </s.TextDescription>
                  </s.Container>
                  
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedbackPartner}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimPartnerNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          
          </ResponsiveWrapperTile>

          



        </ResponsiveWrapper>








        <StyledSeparator alt={"logo"} src={"/config/images/separator-3.png"} />






        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
          >


            
          
            <s.SpacerSmall />
             
              <>
                <s.TextDescriptionN
                  style={{ textAlign: "center", color: "var(--description-text)" }}
                >
                  Hodler chads is a collection of 10k degen chads who diamond hand their way to Mars.
                  <br/>
                  There journey from the floor to Mars is just getting started and they will have to go through
                  <br/>different challenges to make their way to spaceY Shuttle, so they can take off to Mars.
                  <br/><br/>
                  Chads DAO will consist of 4K Normal Chads, 2K Angry Chads, 2K Zombie Chads, 1K Infected Chads,
                  <br/> 900 Dracula Chads, 99 SpaceY Survivor Chads and "1 Alpha Mars Chad (Super Rare)".
                  <br/>
                  In Addition to that, Each category has 1 Rare Chad with Diamond Hands.
                  <br/><br/>
                  The rarity of Hodler chads will determine their power to influence Chad DAO’s proposals.
                  <br/>
                  Main focus of Chads DAO will be to sweep floors of other blue chip NFT projects 
                  <br/>
                  More details about this will be shared during our official roadmap release.🚀<br/>
                </s.TextDescriptionN>
                <s.SpacerXSmall />
                <s.SpacerXSmall />

                <s.TextDescriptionN style={{ textAlign: "center", color: "var(--tile-text)" ,paddingLeft: 20, fontSize:"18px",color:"#ffffff", }}>
                  Mint a HODLER Chad and Join Our Discord and Twitter to Follow Journey of Chad's to Mars
                </s.TextDescriptionN>

                <s.ContainerLeft flex={1} jc={"center"} ai={"center"} flexDirection={"row"}>

                <TouchableOpacity onPress={() =>Linking.openURL(CONFIG.DISCORD_LINK)}>
                  <Image
                    source={{ uri: '/config/images/discord_icon.png' }}
                    // src = '/config/images/discord_icon.png'
                    style={{ width: 50, height: 50 , margin: 20}}
                    />
                </TouchableOpacity>
                <TouchableOpacity onPress={() =>Linking.openURL(CONFIG.TWITTER_LINK)}>
                  <Image
                    source={{ uri: '/config/images/twitter_icon.png' }}
                    // src = '/config/images/twitter_icon.png'
                    style={{ width: 50, height: 50 , margin: 20 }}
                    />
                </TouchableOpacity>
                  </s.ContainerLeft>

                  <StyledSeparator alt={"logo"} src={"/config/images/separator-4.png"} />
                  <s.TextTitle style={{ textAlign: "center", fontSize: 30,fontWeight: "bold",color: "var(--primary)",}}>
                  Chad's Collection
                  </s.TextTitle>
                  <StyledSeparator alt={"logo"} src={"/config/images/separator-3.png"} />

                <ResponsiveWrapperTile>
                <s.Container flex={1} jc={"left"} ai={"center"}>
                  <DpImg
                    alt={"example"}
                    src={"/config/images/base-chad.png"}
                  />
                  <s.TextDescription style={{ textAlign: "center", color: "var(--tile-text)" ,paddingLeft: 20, paddingRight: 20, backgroundColor:"#330B0080", borderRadius:10 }}>
                  Normal Chads : 4,000
                </s.TextDescription>
                </s.Container>

                <s.Container flex={1} jc={"center"} ai={"center"}>
                  <DpImg
                    alt={"example"}
                    src={"/config/images/mid-finger-chad.png"}
                  />
                  <s.TextDescription style={{ textAlign: "center", color: "var(--tile-text)" ,paddingLeft: 20 ,paddingRight: 20, backgroundColor:"#330B0080", borderRadius:10  }}>
                  Angry Chads : 2000
                </s.TextDescription>
                </s.Container>

                <s.Container flex={1} jc={"right"} ai={"center"}>
                  <DpImg
                    alt={"example"}
                    src={"/config/images/zombie-chad.png"}
                  />
                  <s.TextDescription style={{ textAlign: "center", color: "var(--tile-text)" ,paddingLeft: 20,paddingRight: 20, backgroundColor:"#330B0080", borderRadius:10 }}>
                  Zombie Chads : 2,000
                </s.TextDescription>
                </s.Container>
                </ResponsiveWrapperTile>

                <ResponsiveWrapperTile>
                <s.Container flex={1} jc={"left"} ai={"center"}>
                <DpImg
                    alt={"example"}
                    src={"/config/images/infected-zombies.png"}
                  />
                  <s.TextDescription style={{ textAlign: "center", color: "var(--tile-text)" ,paddingLeft: 20 ,paddingRight: 20, backgroundColor:"#330B0080", borderRadius:10 }}>
                  Infected Chads : 1000
                </s.TextDescription>
                </s.Container>

                <s.Container flex={1} jc={"center"} ai={"center"}>
                <DpImg
                    alt={"example"}
                    src={"/config/images/dracula-chads.png"}
                  />
                  <s.TextDescription style={{ textAlign: "center", color: "var(--tile-text)" ,paddingLeft: 20 ,paddingRight: 20, backgroundColor:"#330B0080", borderRadius:10 }}>
                  Dracula Chads : 900 
                </s.TextDescription>
                </s.Container>

                <s.Container flex={1} jc={"left"} ai={"center"}>
                <DpImg
                    alt={"example"}
                    src={"/config/images/space-chad.png"}
                  />
                  <s.TextDescription style={{ textAlign: "center", color: "var(--tile-text)" ,paddingLeft: 20 ,paddingRight: 20, backgroundColor:"#330B0080", borderRadius:10 }}>
                  SpaceY Survior Chads : 99
                </s.TextDescription>
                </s.Container>
                </ResponsiveWrapperTile>

                <ResponsiveWrapperTile>
                <s.Container flex={1} jc={"center"} ai={"center"}>
                  <DpImg
                    alt={"example"}
                    src={"/config/images/spaceY-rare-chad.png"}
                  />
                  <s.TextDescription style={{ textAlign: "center", color: "var(--tile-text)" ,paddingLeft: 20 ,paddingRight: 20, backgroundColor:"#330B0080", borderRadius:10 }}>
                  Alpha Mars Chad : 1 (Super Rare)
                </s.TextDescription>
                </s.Container>
                </ResponsiveWrapperTile>

                {/* <ProgressBar
        percent={75}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
      /> */}

<br/>
<br/>

{(new Date() > launchDate ) ? (
      <s.TextDescription style={{ textAlign: "center",  color: "var(--tile-text)", paddingLeft: 20 }}>
            Mint Status : {data.totalSupply} / {CONFIG.MAX_SUPPLY}
      </s.TextDescription>
      ) : ("")}
      {(new Date() > launchDate ) ? (
      <ResponsiveWrapperTile style={{ flexDirection:'column'}}>
      <ProgressBar completed={data.totalSupply}
      maxCompleted={10000} 
      width="100%"
      isLabelVisible={true}
      baseBgColor="#c7c7c7"
      margin="10px"
      labelColor="#e6d81a"
      labelSize="10px"
      labelAlignment="center"
      customLabel={mintProgressLabel} />
                
                </ResponsiveWrapperTile>
        ) : ("")}
                <s.SpacerSmall />

              </>
            
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>

        











        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
          >


            
          
            <s.SpacerSmall />
             
              <>
              <StyledSeparator alt={"logo"} src={"/config/images/separator-4.png"} />
                  <s.TextTitle style={{ textAlign: "center", fontSize: 30,fontWeight: "bold",color: "var(--primary)",}}>
                  Chad's Spacemap - Coming Soon
                  </s.TextTitle>
              <StyledSeparator alt={"logo"} src={"/config/images/separator-3.png"} />
                
                <s.SpacerXSmall />
                <s.SpacerXSmall />


                <ResponsiveWrapperTile>

                <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/chads-rocket-low.gif"} style={{borderRadius: 10, width:300}} />
          </s.Container>
                          </ResponsiveWrapperTile>

          <s.SpacerSmall />

              </>
            
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>

    

        <s.ContainerLeft flex={1} jc={"right"} ai={"center"} flexDirection={"row"}>
        <s.TextDescription style={{ textAlign: "center", color: "var(--tile-text)" ,paddingLeft: 20,
                fontWeight: "bold", fontSize:"20px",
                color: "#ffffff", }}>
                Become a Hodler
                </s.TextDescription>

                <TouchableOpacity onPress={() =>Linking.openURL(CONFIG.DISCORD_LINK)}>
                  <Image
                    source={{ uri: '/config/images/discord_icon.png' }}
                    // src = '/config/images/discord_icon.png'
                    style={{ width: 50, height: 50 , margin: 20}}
                    />
                </TouchableOpacity>
                <TouchableOpacity onPress={() =>Linking.openURL(CONFIG.TWITTER_LINK)}>
                  <Image
                    source={{ uri: '/config/images/twitter_icon.png' }}
                    // src= '/config/images/twitter_icon.png'
                    style={{ width: 50, height: 50 , margin: 20 }}
                    />
                </TouchableOpacity>
                  </s.ContainerLeft>
      </s.Container>
    </s.Screen>
  );
}

export default App;
